import React from "react";
import styles from "../styles/Instructions.module.scss";
import { Link } from "react-router-dom";
import { breakOnNewlines } from "../utilities/utils";

const firstParagraph =
  "You have recently decided to leave the army and buy a ship " +
  "With the 10,000 credits in your piggy bank and 10,000 from a somewhat reputable " +
  "loan shark, you were able to purchase a small vessel with which to ply your trade." +
  "\n\nYour goal is simple: buy cargo for low prices on one planet and sell it on " +
  "another planet for more than you bought it. Easy, right?" +
  "\nThere are a few things you need to keep in mind:";

const instructionList = [
  "Not all cargoes can be bought and sold on every planet",
  "Occasional economic instability can occur, leading to a cargo having very high or low price",
  "You may be boarded by pirates or by the authorities. Beware",
  "You can store money in the bank for a small return on your investment. While in the bank, it is safe from pirates",
  "The loan shark wants his money back or his pound of flesh. He will wait a short time before demanding one or the other",
  "You can upgrade your ship by visiting a shipyard. Extra bays can be purchased for 800 credits each",
];

const Instructions = () => {
  return (
    <div className={styles.overlay}>
      <div className={styles.popupBox}>
        <div className={styles.title}>Welcome to Sky Smuggler!</div>
        <div className={styles.upperText}>
          {breakOnNewlines(firstParagraph)}
        </div>
        <div className={styles.columnText}>
          {instructionList.map((item, index) => {
            return (
              <div key={index} className={styles.bulletItem}>
                <span className={styles.indent}>{index + 1}.</span>
                <span>{item}</span>
                <br />
              </div>
            );
          })}
        </div>
        <div className={styles.bottomText}>Good luck!</div>
        <Link className={styles.closeButton} to="/" />
      </div>
    </div>
  );
};

export default Instructions;
