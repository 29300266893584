import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../styles/Shipyard.module.scss";
import btnStyles from "../styles/ButtonStyles.module.scss";
import PlanetInfo from "./PlanetInfo";
import { Link } from "react-router-dom";
import { keymaps, Services } from "../configuration/GameSettings";
import PlusMinusInput from "./PlusMinusInput";

class Shipyard extends Component {
  state = {
    desiredBays: 0,
  };

  handleChange = desiredBays => {
    const maxCanAfford = Math.floor(
      this.props.credits / Services.Shipyard.defaultBayCost
    );
    desiredBays = Math.min(
      desiredBays,
      Services.Shipyard.defaultMaxBays - this.props.currentBays,
      maxCanAfford
    );
    desiredBays = Math.max(desiredBays, 0);
    if (desiredBays !== this.state.desiredBays) {
      // don't update if already at zero or maximum bays
      this.setState({ desiredBays: desiredBays });
    }
  };

  stepChange = direction => {
    // calculate values that should be used by plus/minus buttons depending
    // on direction
    let newDesiredBays = this.state.desiredBays;
    const remainder = this.state.desiredBays % 25;

    // if remainder is 0, no change
    // if remainder is nonzero:
    //   - if minus/down, "round" up to nearest 25 then subtract
    //   - if plus/up, "round" down to nearest 25 then add
    if (remainder) {
      newDesiredBays += direction === "down" ? 25 - remainder : -remainder;
    }
    newDesiredBays += direction === "up" ? 25 : -25;

    this.handleChange(newDesiredBays);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // ignore shortcut key if only a state change, not a prop change
    // also ignore if last key pressed was Enter (submit transaction)
    if (
      prevProps.shortcutKey === this.props.shortcutKey &&
      prevState.desiredBays !== this.state.desiredBays
    ) {
      return;
    }

    // if a number is typed, start entering that into the input box
    if (!isNaN(parseInt(this.props.shortcutKey))) {
      const selectedInput = document.getElementById("PMI-buyBaysInput");
      if (selectedInput) {
        selectedInput.focus();
      }
      this.handleChange(parseInt(this.props.shortcutKey));
    }

    switch (this.props.shortcutKey) {
      case keymaps.services.setMax:
        // 'setMax' in this case just adds 25 (there is no max button)
        this.stepChange("up");
        break;
      case keymaps.services.setMin:
        // 'setMin' in this case just subtracts 25 (there is no min button)
        this.stepChange("down");
        break;
      case keymaps.services.execute:
        this.props.handleSubmit("buy", this.state.desiredBays);
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div className={styles.serviceSplitPanel}>
        <PlanetInfo planetName={this.props.planetName} />
        <div className={styles.serviceSplitContent}>
          <div className={styles.serviceHeader}>Shipyard</div>
          <div className={styles.buyBaysCol}>
            <div>Add cargo bays</div>
            <PlusMinusInput
              handlePlus={() => this.stepChange("up")}
              handleMinus={() => this.stepChange("down")}
              handleInputChange={e => this.handleChange(e.target.value)}
              displayValue={this.state.desiredBays}
              id="buyBaysInput"
            />
            <div>
              {this.state.desiredBays * Services.Shipyard.defaultBayCost}{" "}
              credits
            </div>
          </div>
          <div className={styles.serviceFooter}>
            <button
              className={styles.buyButton}
              onClick={() =>
                this.props.handleSubmit("buy", this.state.desiredBays)
              }
            />
            <button className={styles.blankButton} />
          </div>
        </div>

        <Link to="/play/trade" className={btnStyles.backButton} />
      </div>
    );
  }
}

Shipyard.propTypes = {
  credits: PropTypes.number.isRequired,
  currentBays: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  planetName: PropTypes.string.isRequired,
  shortcutKey: PropTypes.string,
};

export default Shipyard;
