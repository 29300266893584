import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../styles/FuelDepot.module.scss";
import btnStyles from "../styles/ButtonStyles.module.scss";
import { Link } from "react-router-dom";
import PlanetInfo from "./PlanetInfo";
import PlusMinusInput from "./PlusMinusInput";
import { keymaps, Services } from "../configuration/GameSettings";
import { withCommas } from "../utilities/utils";

class FuelDepot extends Component {
  state = {
    desiredCells: 0,
    price: 0,
  };

  handleInputChange = inputCells => {
    const cells = Math.max(parseInt(inputCells), 0);

    if (cells === this.state.desiredCells) {
      return;
    }

    let newTotalPurchases = Math.max(
      cells / 5 - 1 + this.props.fuelPurchases,
      0
    );

    const initialPrice = Services.FuelDepot.initialPrice;
    let price = this.state.price;

    if (cells > this.state.desiredCells) {
      price += initialPrice + initialPrice * Math.pow(newTotalPurchases, 3);
    } else if (cells < this.state.desiredCells) {
      price -= initialPrice + initialPrice * Math.pow(newTotalPurchases + 1, 3);
    }

    if (price <= this.props.credits) {
      this.setState({ desiredCells: cells, price: Math.max(price, 0) });
    }
  };

  stepChange = direction => {
    let newCells = this.state.desiredCells + (direction === "up" ? 5 : -5);

    this.handleInputChange(newCells);
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      prevProps.shortcutKey === this.props.shortcutKey &&
      prevState.desiredCells !== this.state.desiredCells
    ) {
      return;
    }

    switch (this.props.shortcutKey) {
      case keymaps.services.setMax:
        this.stepChange("up");
        break;
      case keymaps.services.setMin:
        this.stepChange("down");
        break;
      case keymaps.services.execute:
        this.props.handleSubmit("fueldepot", "buy", this.state.desiredCells);
        break;
      default:
    }
  }

  render() {
    return (
      <div className={styles.serviceSplitPanel}>
        <PlanetInfo planetName={this.props.planetName} />
        <div className={styles.serviceSplitContent}>
          <div className={styles.serviceHeader}>Fuel Depot</div>
          <div className={styles.buyTurnsCol}>
            <div>Add fuel cells</div>
            <PlusMinusInput
              handlePlus={() => this.stepChange("up")}
              handleMinus={() => this.stepChange("down")}
              displayValue={this.state.desiredCells}
              disabled={true}
            />
            <div>{withCommas(this.state.price)} credits</div>
          </div>
          <div className={styles.serviceFooter}>
            <button
              className={styles.buyButton}
              onClick={() =>
                this.props.handleSubmit(
                  "fueldepot",
                  "buy",
                  this.state.desiredCells
                )
              }
            />
            <button className={styles.blankButton} />
          </div>
        </div>

        <Link to="/play/trade" className={btnStyles.backButton} />
      </div>
    );
  }
}

FuelDepot.propTypes = {
  planetName: PropTypes.string.isRequired,
  credits: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  fuelPurchases: PropTypes.number.isRequired,
  shortcutKey: PropTypes.string,
};

export default FuelDepot;
