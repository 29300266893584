import React from "react";
import PropTypes from "prop-types";
import styles from "../styles/PlusMinusInput.module.scss";

const PlusMinusInput = props => (
  <div className={styles.plusMinusInputGroup}>
    <div className={styles.inputPlus} onClick={props.handlePlus} />
    <input
      type="number"
      disabled={props.disabled ? "disabled" : ""}
      value={props.displayValue}
      onChange={props.handleInputChange}
      id={`PMI-${props.id}`}
    />
    <div className={styles.inputMinus} onClick={props.handleMinus} />
  </div>
);

PlusMinusInput.propTypes = {
  handlePlus: PropTypes.func.isRequired,
  handleMinus: PropTypes.func.isRequired,
  displayValue: PropTypes.number.isRequired,
  handleInputChange: PropTypes.func,
  disabled: PropTypes.bool,
};

export default PlusMinusInput;
