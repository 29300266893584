import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../styles/ConsoleTextInput.module.css";

export const MAX_LENGTH = 10;

class ConsoleTextInput extends Component {
  constructor(props) {
    super(props);
    this.state = {
      currentWidth: { width: "1em" },
      inputText: "",
    };
    this.writeIt = this.writeIt.bind(this);
    this.setSetterFocus = this.setSetterFocus.bind(this);

    // Set the type of cursor, either underline or block
    if (this.props.cursorType === "block") {
      this.cursorStyle = styles.cursorBlock;
      this.cursorText = "_";
    } else {
      this.cursorStyle = styles.cursorUnderline;
      this.cursorText = "_____";
    }
  }

  writeIt(event) {
    let textIn = event.target.value;
    // Strip out newline characters
    textIn = textIn.replace(/(\r\n|\n|\r)/gm, "");

    // Call parent-supplied method to update text. Input box text is not
    // stored in this component, only in the parent component. This makes it
    // easier to grab that text when submitting the score name
    this.props.handleTextChanged(textIn);

    // If the Enter key (13) is pressed, submit the score name
    if (event.key === "Enter") {
      this.props.handleSubmit();
    }
    // Block moving left and right
    if (event.key === "ArrowLeft" || event.key === "ArrowRight") {
      event.preventDefault();
    }
  }

  componentDidMount() {
    // Automatically focus the correct input box when component is mounted
    if (this.setter) {
      this.setter.focus();
    }
  }

  setSetterFocus() {
    // If the element actually showing the text is clicked, focus the
    // hidden element that is collecting the text
    if (this.setter) {
      this.setter.focus();
    }
  }

  getVisibleText() {
    let text = this.props.currentText;
    // Convert regular spaces to non-breaking space so multiple spaces will
    // show in the span
    text = text.replace(/ /g, "\u00a0");
    // Remove any newlines
    text = text.replace(/(\r\n|\n|\r)/gm, "");
    // Limit it to 27 characters
    text = text.substring(0, MAX_LENGTH);
    return text;
  }

  render() {
    return (
      <div
        className={styles.backgroundBox}
        onClick={this.setSetterFocus}
        data-cy="backgroundBox"
      >
        <textarea
          autoFocus={true}
          maxLength={MAX_LENGTH}
          className={styles.hiddenInputBox}
          onKeyDown={this.writeIt}
          onKeyUp={this.writeIt}
          onKeyPress={this.writeIt}
          ref={setter => (this.setter = setter)}
          data-cy="hiddenInputBox"
          defaultValue={this.getVisibleText()}
        />
        <div>
          <span className={styles.visibleText} data-cy="visibleText">
            {this.getVisibleText()}
          </span>
          {/*The <b> element is actually making the cursor.*/}
          <b className={this.cursorStyle} data-cy="cursor">
            {this.cursorText}
          </b>
        </div>
      </div>
    );
  }
}

export const cursorTypes = {
  block: "block",
  underline: "underline",
};

ConsoleTextInput.cursorTypes = cursorTypes;

ConsoleTextInput.propTypes = {
  currentText: PropTypes.string.isRequired,
  cursorType: PropTypes.string,
  handleSubmit: PropTypes.func.isRequired,
  handleTextChanged: PropTypes.func.isRequired,
};

export default ConsoleTextInput;
