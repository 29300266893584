import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../styles/Popup.module.scss";
import ConsoleTextInput from "./ConsoleTextInput";

class Popup extends Component {
  constructor(props) {
    super(props);
    // If this popup has a text-input box, the text there is stored in the
    // popup's state, not the text box's state
    this.state = {
      inputText: "",
    };

    // Bind methods so that `this` works in the callback
    this.onSubmit = this.onSubmit.bind(this);
    this.onTextChanged = this.onTextChanged.bind(this);

    // Default to info header color
    const titleColor =
      this.props.titleType === "warning" ? styles.textWarning : styles.textInfo;
    this.titleStyle = `${styles.title} ${titleColor}`;

    // Default to acknowledge button
    const buttonImage =
      this.props.buttonType === "submit" ? styles.submit : styles.acknowledge;
    this.buttonStyle = `${styles.buttonBase} ${buttonImage}`;

    this.hasInput = this.props.buttonType === "submit";
  }

  onSubmit() {
    // Call the parent's specified method to submit
    this.props.handlePopupButton(this.state.inputText);
  }

  onTextChanged(text) {
    // Update popup's state with the next input box text
    this.setState({ inputText: text });
  }

  render() {
    return (
      <div className={styles.overlay}>
        <div className={styles.popupBox} data-cy="popupContainer">
          <div className={this.titleStyle}>{this.props.title}</div>
          <div className={styles.message}>{this.props.message}</div>
          {this.hasInput && (
            <ConsoleTextInput
              handleSubmit={this.onSubmit}
              handleTextChanged={text => this.onTextChanged(text)}
              currentText={this.state.inputText}
              cursorType={"underline"}
            />
          )}
          <button
            className={this.buttonStyle}
            onClick={() => {
              this.props.handlePopupButton(this.state.inputText);
            }}
            data-cy="popupSubmitButton"
          />
        </div>
      </div>
    );
  }
}

export const titleTypes = {
  info: "info",
  warning: "warning",
};

export const buttonTypes = {
  acknowledge: "acknowledge",
  submit: "submit",
};

Popup.propTypes = {
  buttonType: PropTypes.oneOf(Object.keys(buttonTypes)),
  handlePopupButton: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  titleType: PropTypes.oneOf(Object.keys(titleTypes)),
};

Popup.titleTypes = titleTypes;
Popup.buttonTypes = buttonTypes;

export default Popup;

export const GameLosePopup = props => (
  <Popup
    title="Retirement beckons"
    message={
      "You wake up one day realizing that your life is going nowhere fast. " +
      "In a sudden-onset mid-life crisis, you sell your ship and all your " +
      "cargo and move to the tropical planet of Obscurium. Well played, Captain."
    }
    titleType={Popup.titleTypes.info}
    handlePopupButton={props.btnCallback}
  />
);

GameLosePopup.propTypes = {
  btnCallback: PropTypes.func.isRequired,
};

export const GameWinPopup = props => (
  <Popup
    title="Congratulations!"
    message="You have made it to the top score! Please enter your name here:"
    titleType={Popup.titleTypes.info}
    buttonType={Popup.buttonTypes.submit}
    handlePopupButton={props.btnCallback}
    tabIndex={0}
  />
);

GameWinPopup.propTypes = {
  btnCallback: PropTypes.func.isRequired,
};
