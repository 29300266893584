import React, { Component } from "react";
import styles from "../styles/Bank.module.scss";
import PlanetInfo from "./PlanetInfo";
import PropTypes from "prop-types";
import btnStyles from "../styles/ButtonStyles.module.scss";
import { Link } from "react-router-dom";
import { withCommas } from "../utilities/utils";
import { keymaps } from "../configuration/GameSettings";

class Bank extends Component {
  state = {
    withdrawAmt: 0,
    depositAmt: 0,
    selectedSide: "withdraw",
  };

  handleInputChange(amount, side) {
    if (side === "withdraw") {
      let withdrawAmt;
      if (amount === "max") {
        withdrawAmt = this.props.bankBalance;
      } else {
        withdrawAmt = Math.min(amount, this.props.bankBalance);
      }
      this.setState({ withdrawAmt: withdrawAmt, depositAmt: 0 });
    } else if (side === "deposit") {
      let depositAmt;
      if (amount === "max") {
        depositAmt = this.props.credits;
      } else {
        depositAmt = Math.min(amount, this.props.credits);
      }
      this.setState({ depositAmt: depositAmt, withdrawAmt: 0 });
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.shortcutKey === this.props.shortcutKey) {
      return;
    }

    if (!isNaN(parseInt(this.props.shortcutKey))) {
      const selectedInput = document.getElementById(
        `bank-${this.state.selectedSide}`
      );
      if (selectedInput) {
        selectedInput.focus();
        this.handleInputChange(this.props.shortcutKey, this.state.selectedSide);
      }
    }

    switch (this.props.shortcutKey) {
      case keymaps.services.sideNext:
      case keymaps.services.sidePrev:
        this.setState({
          selectedSide:
            this.state.selectedSide === "withdraw" ? "deposit" : "withdraw",
        });
        break;
      case keymaps.services.setMax:
        this.handleInputChange("max", this.state.selectedSide);
        break;
      case keymaps.services.setMin:
        this.handleInputChange(0, this.state.selectedSide);
        break;
      case keymaps.services.execute:
        const amt = this.state[`${this.state.selectedSide}Amt`];
        this.props.handleSubmit(this.state.selectedSide, amt);
        break;
      default:
    }
  }

  render() {
    return (
      <div className={styles.serviceSplitPanel}>
        <PlanetInfo planetName={this.props.planetName} />
        <div className={styles.serviceSplitContent}>
          <div className={styles.serviceHeader} data-cy={"bankBalance"}>
            Bank Balance: {withCommas(this.props.bankBalance)}
          </div>
          <div className={styles.withdrawCol}>
            <div
              className={
                this.state.selectedSide === "withdraw"
                  ? styles.selectedSide
                  : ""
              }
            >
              Withdraw Amount
            </div>
            <input
              className={
                this.state.selectedSide === "withdraw"
                  ? `${styles.bankInput} ${styles.selectedSide}`
                  : styles.bankInput
              }
              type="number"
              id="bank-withdraw"
              value={this.state.withdrawAmt}
              onChange={e => this.handleInputChange(e.target.value, "withdraw")}
            />
            <button
              className={styles.buttonMax}
              onClick={() => this.handleInputChange("max", "withdraw")}
            />
          </div>
          <div className={styles.depositCol}>
            <div
              className={
                this.state.selectedSide === "deposit" ? styles.selectedSide : ""
              }
            >
              Deposit Amount
            </div>
            <input
              className={
                this.state.selectedSide === "deposit"
                  ? `${styles.bankInput} ${styles.selectedSide}`
                  : styles.bankInput
              }
              type="number"
              id="bank-deposit"
              value={this.state.depositAmt}
              onChange={e => this.handleInputChange(e.target.value, "deposit")}
            />
            <button
              className={styles.buttonMax}
              onClick={() => this.handleInputChange("max", "deposit")}
              data-cy={"depositMaxButton"}
            />
          </div>
          <div className={styles.serviceFooter}>
            <button
              className={styles.buttonWithdraw}
              onClick={() =>
                this.props.handleSubmit("withdraw", this.state.withdrawAmt)
              }
            />
            <button
              className={styles.buttonDeposit}
              data-cy={"depositButton"}
              onClick={() =>
                this.props.handleSubmit("deposit", this.state.depositAmt)
              }
            />
          </div>
        </div>

        <Link to="/play/trade" className={btnStyles.backButton} />
      </div>
    );
  }
}

Bank.propTypes = {
  planetName: PropTypes.string.isRequired,
  bankBalance: PropTypes.number.isRequired,
  credits: PropTypes.number.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  shortcutKey: PropTypes.string,
};

export default Bank;
