import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "../styles/Console.module.css";
import btnStyles from "../styles/ButtonStyles.module.scss";

const planetNames = [
  "caliban",
  "earth",
  "pertia",
  "setebos",
  "taspra",
  "umbriel",
];

const Travel = props => {
  // After this component has mounted, shift focus back to parent element that
  // will handle all keyboard shortcuts
  useEffect(() => props.dropFocus());

  // Avoid multiple re-renderings if game isn't gotten from server yet
  if (props.currentGame.planet == null) {
    return null;
  }

  return (
    <div className={styles.panelTravel}>
      {/* Back Button */}
      <Link
        to="/play/trade"
        className={btnStyles.backButton}
        data-cy={"backButton"}
      />

      {/* Planet Buttons:
       *  - Loop through the list of all planet names and create a link for each
       */}
      {planetNames.map(planet => {
        const isDisabled =
          props.currentGame.planet &&
          props.currentGame.planet.toLowerCase() === planet.toLowerCase();
        return (
          <button
            key={planet}
            className={btnStyles.planetButton}
            id={styles[planet]}
            onClick={() => props.doTravel(planet)}
            disabled={isDisabled}
            data-cy={`travel-${planet}`}
          />
        );
      })}
    </div>
  );
};

export default Travel;
