import React, { Component } from "react";
import PropTypes from "prop-types";
import styles from "../styles/Loanshark.module.scss";
import btnStyles from "../styles/ButtonStyles.module.scss";
import { Link } from "react-router-dom";
import PlanetInfo from "./PlanetInfo";
import { withCommas } from "../utilities/utils";
import { keymaps, Services } from "../configuration/GameSettings";

class Loanshark extends Component {
  state = {
    borrowAmt: 0,
    repayAmt: 0,
    selectedSide: "borrow",
  };

  maxLoanAmount = Services.LoanShark.defaultMaxLoan;
  availableCreditLine = Math.max(
    0,
    this.maxLoanAmount - this.props.loanBalance
  );

  handleInputChange = (amount, side) => {
    if (side === "borrow") {
      let borrowAmt;
      if (amount === "max") {
        borrowAmt = this.availableCreditLine;
      } else {
        borrowAmt = Math.max(Math.min(amount, this.availableCreditLine), 0);
      }
      this.setState({ borrowAmt: borrowAmt, repayAmt: 0 });
    } else if (side === "repay") {
      let repayAmt;
      if (amount === "max") {
        repayAmt = Math.min(this.props.loanBalance, this.props.credits);
      } else {
        repayAmt = Math.max(Math.min(amount, this.props.loanBalance), 0);
      }
      this.setState({ repayAmt: repayAmt, borrowAmt: 0 });
    }
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    // ignore shortcut key if only a state change, not a prop change
    // also ignore if last key pressed was Enter (submit transaction)
    if (
      prevProps.shortcutKey === this.props.shortcutKey &&
      prevState !== this.state
    ) {
      return;
    }

    // if a number is typed, start entering that into the input box
    if (!isNaN(parseInt(this.props.shortcutKey))) {
      const selectedInput = document.getElementById(
        `loanshark-${this.state.selectedSide}`
      );
      if (selectedInput) {
        selectedInput.focus();
      }
      this.handleInputChange(
        parseInt(this.props.shortcutKey),
        this.state.selectedSide
      );
    }

    switch (this.props.shortcutKey) {
      case keymaps.services.sideNext:
      case keymaps.services.sidePrev:
        this.setState({
          selectedSide:
            this.state.selectedSide === "borrow" ? "repay" : "borrow",
        });
        break;
      case keymaps.services.setMax:
        this.handleInputChange("max", this.state.selectedSide);
        break;
      case keymaps.services.setMin:
        this.handleInputChange(0, this.state.selectedSide);
        break;
      case keymaps.services.execute:
        this.props.doTransaction(
          this.state.selectedSide,
          this.state[`${this.state.selectedSide}Amt`]
        );
        break;
      default:
        break;
    }
  }

  render() {
    return (
      <div className={styles.serviceSplitPanel}>
        <PlanetInfo planetName={this.props.planetName} />
        <div className={styles.serviceSplitContent}>
          <div className={styles.serviceHeader}>Loan Shark</div>
          <div
            className={
              this.state.selectedSide === "borrow"
                ? `${styles.borrowCol} ${styles.selectedSide}`
                : styles.borrowCol
            }
          >
            <div>Credit remaining: {withCommas(this.availableCreditLine)}</div>
            <input
              type="number"
              id="loanshark-borrow"
              className={
                this.state.selectedSide === "borrow" ? styles.selectedSide : ""
              }
              value={this.state.borrowAmt}
              onChange={e => this.handleInputChange(e.target.value, "borrow")}
            />
            <button
              className={styles.buttonMax}
              onClick={() => this.handleInputChange("max", "borrow")}
            />
          </div>
          <div
            className={
              this.state.selectedSide === "repay"
                ? `${styles.repayCol} ${styles.selectedSide}`
                : styles.repayCol
            }
          >
            <div>Outstanding balance: {withCommas(this.props.loanBalance)}</div>
            <input
              type="number"
              id="loanshark-repay"
              className={
                this.state.selectedSide === "repay" ? styles.selectedSide : ""
              }
              value={this.state.repayAmt}
              onChange={e => this.handleInputChange(e.target.value, "repay")}
            />
            <button
              className={styles.buttonMax}
              onClick={() => this.handleInputChange("max", "repay")}
            />
          </div>
          <div className={styles.serviceFooter}>
            <button
              className={styles.buttonBorrow}
              onClick={() =>
                this.props.doTransaction("borrow", this.state.borrowAmt)
              }
            />
            <button
              className={styles.buttonRepay}
              onClick={() =>
                this.props.doTransaction("repay", this.state.repayAmt)
              }
            />
          </div>
        </div>

        {/* Back button */}
        <Link to="/play/trade" className={btnStyles.backButton} />
      </div>
    );
  }
}

Loanshark.propTypes = {
  credits: PropTypes.number.isRequired,
  doTransaction: PropTypes.func.isRequired,
  dropFocus: PropTypes.func.isRequired,
  loanBalance: PropTypes.number.isRequired,
  planetName: PropTypes.string.isRequired,
  shortcutKey: PropTypes.string.isRequired,
};

export default Loanshark;
