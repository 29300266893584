import planetEarth from "../images/decoration/planetEarth.png";
import planetTaspra from "../images/decoration/planetTaspra.png";
import planetCaliban from "../images/decoration/planetCaliban.png";
import planetUmbriel from "../images/decoration/planetUmbriel.png";
import planetSetebos from "../images/decoration/planetSetebos.png";
import planetPertia from "../images/decoration/planetPertia.png";

export const planetImages = {
  caliban: planetCaliban,
  earth: planetEarth,
  pertia: planetPertia,
  setebos: planetSetebos,
  taspra: planetTaspra,
  umbriel: planetUmbriel,
};

export const flavorText = {
  caliban: {
    age: "3m",
    name: "caliban",
    population: "85m",
    species: "humanoid",
    stance: "peaceful",
  },
  earth: {
    age: "20m",
    name: "earth",
    population: "10b",
    species: "humanoid",
    stance: "mostly harmless",
  },
  pertia: {
    age: "3m",
    name: "pertia",
    population: "10.5m",
    species: "ice ape",
    stance: "neutral",
  },
  setebos: {
    age: "12m",
    name: "setebos",
    population: "2m",
    species: "sandworm",
    stance: "neutral",
  },
  taspra: {
    age: "36m",
    name: "taspra",
    population: "487m",
    species: "insect",
    stance: "neutral",
  },
  umbriel: {
    age: "26m",
    name: "umbriel",
    population: "34m",
    species: "goblin",
    stance: "peaceful",
  },
};

export const keymaps = {
  cargoNext: "ArrowDown",
  cargoPrev: "ArrowUp",
  dismissPopups: [" ", "Esc", "Escape", "Space", "Enter"],
  deselectInput: ["Esc", "Escape", "Enter", "Tab"],
  do_buy: "q",
  do_sell: "e",
  maxBuy: "ArrowLeft",
  maxSell: "ArrowRight",
  planets: {
    a: "setebos",
    d: "caliban",
    e: "taspra",
    q: "pertia",
    s: "umbriel",
    w: "earth",
  },
  service: "s",
  travelScreen: " ",
  services: {
    sideNext: "ArrowRight",
    sidePrev: "ArrowLeft",
    setMax: "ArrowUp",
    setMin: "ArrowDown",
    execute: "Enter",
  },
};

export const DEFAULT_CARGO = "mining";

export const Services = {
  PlanetDirectory: {
    caliban: null,
    earth: "bank",
    pertia: "fueldepot",
    setebos: null,
    taspra: "shipyard",
    umbriel: "loanshark",
  },
  Shipyard: {
    availablePlanets: ["taspra"],
    defaultBayCost: 800,
    defaultMaxBays: 1000,
  },
  LoanShark: {
    availablePlanets: ["umbriel"],
    defaultInterest: 0.25,
    defaultMaxLoan: 50000,
    initialLoan: 10000,
    loanTerm: 10,
  },
  FuelDepot: {
    initialPrice: 50000,
  },
};
