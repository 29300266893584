import React, { Component } from "react";
import { Link, Redirect, Route, Switch } from "react-router-dom";

import axios from "axios";

import "../styles/Game.css";
import styles from "../styles/Game.module.css";
import "../styles/sky.css";

import Travel from "./Travel";
import Trade from "./Trade";
import Bank from "./Bank";
import FuelDepot from "./FuelDepot";
import Loanshark from "./Loanshark";
import Shipyard from "./Shipyard";
import Popup, { GameLosePopup, GameWinPopup } from "./Popup";
import {
  DEFAULT_CARGO,
  keymaps,
  Services,
} from "../configuration/GameSettings";

import StatusBar from "./StatusBar";

import console_background from "../images/background/gamebkgd.jpg";
import { orderedCargos } from "../utilities/utils";

const PLAY_BUTTON_RESUMES_GAME = false;

const zeroMarketInputs = {
  medical: 0,
  metal: 0,
  mining: 0,
  narcotics: 0,
  water: 0,
  weapons: 0,
};

const emptyGame = {
  bankBalance: 0,
  credits: 0,
  currentHold: zeroMarketInputs,
  isActive: false,
  loanBalance: 0,
  planet: "earth",
  totalBays: 0,
  turnsLeft: 0,
  fuelPurchases: 0,
  usedBays: 0,
};

class Game extends Component {
  state = {
    currentGame: { ...emptyGame },
    currentMarket: { ...zeroMarketInputs },
    currentPopup: null,
    gameId: null,
    goToNext: null,
    lastShortcutKey: "",
    marketInput: { ...zeroMarketInputs },
    nextTransactionType: null,
    queuedPopups: [],
    selectedCargo: DEFAULT_CARGO,
    shipyardInput: 0,
    wantToExit: false,
    warnedOfCargo: false,
  };

  // focusHolder is a reference to an empty element that will listen for and
  // react to shortcut keys
  focusHolder = React.createRef();

  static deleteGame() {
    // Remove the 'saved' game from browser local storage
    window.localStorage.removeItem("currentGameId");
  }

  getGameState = () => {
    if (process.env.NODE_ENV === "test") {
      return;
    }
    // Try to get locally cached gameId:
    let storedGameId = window.localStorage.getItem("currentGameId");

    if (storedGameId == null || !PLAY_BUTTON_RESUMES_GAME) {
      // Get a new game from the server
      axios
        .get("/game/new_game")
        .then(response => {
          const { currentMarket, gameId, gameState } = response.data;
          if (gameId == null || gameState == null) {
            console.warn("Bad response from server on new game request.");
            return;
          }
          // Set component state with response from server
          this.setState({
            currentGame: gameState,
            currentMarket: currentMarket,
            gameId: gameId,
            goToNext: "/play/trade",
          });
          this.parseResponsePopups(response.data);

          // Save the gameId to browser storage
          window.localStorage.setItem("currentGameId", gameId);
        })
        .catch(error => {
          console.warn(`Failed getting a new game: ${error}`);
        });
    } else {
      // gameId was found in local storage, try to pull game state from API
      axios
        .get("/game/game_state", { params: { gameId: storedGameId } })
        .then(response => {
          let { gameId, gameState, currentMarket } = response.data;
          this.setState({
            currentGame: gameState,
            currentMarket: currentMarket,
            gameId: gameId,
          });
          this.parseResponsePopups(response.data);
        })
        .catch(error => {
          console.warn(`Error retrieving game state: ${error}`);
          this.enqueuePopups([
            <Popup
              title={"Unknown ship serial number!"}
              message={
                "Your ship registration seems be invalid, and the port police seize your vessel. As luck would have it, a mysterious benefactor sends you the title to a brand new ship a few days later."
              }
              titleType={Popup.titleTypes.warning}
              handlePopupButton={this.clearCurrentPopup}
            />,
          ]);

          this.setState({ gameId: null });
          window.localStorage.removeItem("currentGameId");
          this.getGameState();
        });
    }
  };

  componentDidMount() {
    this.getGameState();
    // Ensure focus starts on the keyboard shortcut listener
    if (this.focusHolder.current) {
      this.focusHolder.current.focus();
    }
  }

  componentWillUnmount() {
    this.setState({ gameId: null });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check if we were just redirected to this page (i.e., Travel -> Trade)
    // and clear the navigation flag if so
    if (
      this.state.goToNext &&
      window.location.pathname === this.state.goToNext
    ) {
      this.setState({ goToNext: null, lastShortcutKey: "" });
    }

    // If there are notification popups in the queue, but none currently
    // displayed, pop and show one
    if (this.state.queuedPopups.length > 0 && this.state.currentPopup == null) {
      this.setState({ currentPopup: this.state.queuedPopups.pop() });
    }

    if (document.activeElement.nodeName !== "INPUT") {
      // Don't automatically drop focus from an input box. The market input
      // boxes have their own mechanism to dismiss focus when desired.
      // Otherwise, set focus to the keyboard shortcut handler
      this.setFocusHolderFocus();
    }
  }

  postTravel = planetName => {
    // Clear market buy/sell input boxes
    this.setState({ marketInput: zeroMarketInputs });

    if (this.state.currentGame.turnsLeft > 1) {
      axios
        .post("/game/travel", {
          gameId: this.state.gameId,
          toPlanet: planetName,
        })
        .then(res => {
          const { gameState, currentMarket } = res.data;
          this.setState({
            currentGame: gameState,
            currentMarket: currentMarket,
            goToNext: "/play/trade",
          });
          this.parseResponsePopups(res.data);
        })
        .catch(err => {
          console.warn(err);
        })
        .then(() => {
          // Set the selected cargo on the new planet
          let selectedCargo = this.state.selectedCargo || DEFAULT_CARGO;
          if (this.state.currentMarket[selectedCargo] == null) {
            // After travel, the previously selected cargo may be banned on the
            // new planet, so cycle to the next one instead.
            selectedCargo = orderedCargos[selectedCargo].next;
          }
          // Reset the expected txn type as well, since market inputs are cleared
          this.setState({
            nextTransactionType: null,
            selectedCargo: selectedCargo,
          });
        });
    }
  };

  hasNoCargo = () => {
    const hold = this.state.currentGame.currentHold;
    const empty = zeroMarketInputs;
    return Object.keys(hold).every(key => hold[key] === empty[key]);
  };

  handleEndGame = () => {
    if (!this.state.warnedOfCargo && !this.hasNoCargo()) {
      this.setState({ warnedOfCargo: true });
      // Show a popup warning reminding of cargo left in the hold
      this.enqueuePopups([
        <Popup
          handlePopupButton={this.clearCurrentPopup}
          message={
            "You have a bad feeling about travelling and should probably sell all your cargo..."
          }
          title={"Consulted a psychic"}
        />,
      ]);
      return;
    }
    axios
      .post("/scores/submit", { gameId: this.state.gameId })
      .then(res => {
        if (
          res.data.hasOwnProperty("scoreTypes") &&
          res.data.scoreTypes.length === 0
        ) {
          // Not a high score
          this.enqueuePopups([
            <GameLosePopup btnCallback={this.clearCurrentPopup} />,
          ]);
        } else {
          // Is a high score of at least one type
          const gameId = this.state.gameId;
          this.enqueuePopups([
            <GameWinPopup
              btnCallback={name => this.handleSubmitName(name, gameId)}
            />,
          ]);
        }
      })
      .catch(error => {
        console.warn("Error submitting high score: ");
        console.warn(error.response.status, error.response.data);
      })
      .then(() => {
        Game.deleteGame();
        this.setState({ goToNext: "/" });
      });
  };

  clearCurrentPopup = () => {
    // Just set it to null so it won't display any more
    this.setState({ currentPopup: null });
  };

  handleSubmitName = (submittedName, gameId) => {
    if (!gameId) {
      Game.deleteGame();
      return;
    }
    // Send the desired high score name to the server to associate with the
    // score of the game that was just finished
    axios
      .post("/scores/update_name", {
        gameId: gameId,
        newName: "" + submittedName,
      })
      .then(() => {
        console.info(`submitted name (${submittedName}) to high scores`);
      })
      .catch(err => {
        console.info(
          `failure when submitting name: ${err.response.status}, ${
            err.response.data
          }`
        );
      })
      .then(() => {
        this.setState({ goToNext: "/" });
        this.setState({ currentPopup: null });
      });
  };

  handleMarketChange = (cargoType, value) => {
    const maxHoldSize = Services.Shipyard.defaultMaxBays;
    // Clamp desired cargo at the maximum hold size (not actual hold size
    if (value > maxHoldSize) {
      value = maxHoldSize;
    }
    // Clamp desired cargo at 0 for a minimum. Cannot transact negative qty.
    if (value < 0) {
      value = 0;
    }

    // If change is not due to a buy_max/sell_max, don't assume expected
    // transaction type
    let nextTransactionType = null;

    if (value === "buy_max") {
      const availableBays =
        this.state.currentGame.totalBays - this.state.currentGame.usedBays;
      const price = this.state.currentMarket[cargoType];
      const credits = this.state.currentGame.credits;

      // Max can buy is limited either by space or credits
      value = Math.min(Math.floor(credits / price), availableBays);
      // Hitting Enter will default to a Buy transaction
      nextTransactionType = "buy";
    }
    if (value === "sell_max") {
      // Sell max is only limited by how many of the cargo are on hand
      value = this.state.currentGame.currentHold[cargoType];
      // Hitting Enter will default to a Sell transaction
      nextTransactionType = "sell";
    }

    // To make things simpler for keyboard shortcuts and max buy/sell, only
    // allow one transaction at a time, so start with all zeros:
    let currentQtys = { ...zeroMarketInputs };
    // Set this particular cargo quantity as determined above
    currentQtys[cargoType] = value;
    this.setState({
      marketInput: currentQtys,
      nextTransactionType: nextTransactionType,
    });
  };

  postTradeTxn = transactionType => {
    axios
      .post("/game/trade", {
        gameId: this.state.gameId,
        transaction: { side: transactionType, ...this.state.marketInput },
      })
      .then(res => {
        const { gameState, currentMarket } = res.data;
        this.setState({
          currentGame: gameState,
          currentMarket: currentMarket,
        });
      })
      .catch(err => {
        console.log(err.response.data);
        // Failed transactions may have a popup notification saying why
        // the transaction failed
        this.parseResponsePopups(err.response.data);
      })
      .then(() => {
        this.setState({
          marketInput: zeroMarketInputs,
          nextTransactionType: null,
        });
      });
  };

  postTxn = (service, side, qty) => {
    // navigate to trade page first to avoid rerendering the service screen
    this.setState({ goToNext: "/play/trade" });
    axios
      .post(`/game/${service}`, {
        gameId: this.state.gameId,
        transaction: { side: side, qty: qty },
      })
      .then(res => {
        const { gameState, currentMarket } = res.data;
        this.setState({ currentGame: gameState, currentMarket: currentMarket });
        // Bank may have notification after successful transaction
        this.parseResponsePopups(res.data);
      })
      .catch(err => {
        console.log(err.response.data);
        this.parseResponsePopups(err.response.data);
      });
  };

  parseResponsePopups(apiResponse) {
    const { notifications } = apiResponse;
    if (!notifications) {
      // No notifications/popups to display
      return;
    }

    // Generate an array of Popups for each notification sent by API
    const newPopups = notifications.map(popupData => {
      const { title, message, titleType } = popupData;
      return (
        <Popup
          title={title}
          message={message}
          titleType={titleType}
          handlePopupButton={this.clearCurrentPopup}
        />
      );
    });
    // Add popup(s) to the queue to be displayed, one at a time
    this.enqueuePopups(newPopups);
  }

  enqueuePopups(newPopups) {
    // If a single popup was passed, convert it into an Array instead
    if (!Array.isArray(newPopups)) {
      newPopups = [newPopups];
    }
    // Append new popups to any already in the queue
    const newPopupQueue = this.state.queuedPopups.concat(newPopups);
    // Set state with the new (appended) list of queued popups
    this.setState({ queuedPopups: newPopupQueue });
  }

  setFocusHolderFocus = () => {
    if (!this.focusHolder.current) {
      // Reference hasn't been assigned yet, so can't set focus. This may
      // happen before the component is mounted
      return;
    }

    if (
      this.state.currentPopup &&
      this.state.currentPopup.type === GameWinPopup
    ) {
      // Don't enable shortcuts while trying to type name into high score box
      return;
    }
    // Another component wants to drop focus, so refocus the element
    // that is handling shortcut keys
    this.focusHolder.current.focus();
  };

  handleShortcuts = event => {
    let shortcutKey = event.key;
    if (document.activeElement !== this.focusHolder.current) {
      // an input box may have focus, so don't take it away unless specifically
      // desired
      if (!keymaps.deselectInput.includes(shortcutKey)) {
        return;
      } else {
        // input box was selected, and user wants to Tab/Esc/Enter out of it
        this.setFocusHolderFocus();
        // set an empty shortcut to be passed to child component
        shortcutKey = "";
      }
    }

    // Ignore the keypress if Alt/Ctrl/Cmd were pressed as well
    if (event.altKey || event.ctrlKey || event.metaKey || event.shiftKey) {
      return;
    }

    // Keyboard shortcuts with a popup active:
    if (this.state.currentPopup) {
      if (
        this.state.currentPopup.type !== GameWinPopup &&
        keymaps.dismissPopups.includes(shortcutKey)
      ) {
        // Dismiss a 'normal' popup (defaults to any of [Esc, Space, Enter])
        // Ignores a score-name-submit popup
        this.clearCurrentPopup();
      }
      // If it's a submit-type popup (requires typing in a box), then disable
      // the rest of the shortcut checking.
      return;
    }

    // Keyboard shortcuts while on the Trade Screen
    if (window.location.pathname === "/play/trade") {
      // If a number pressed, select the input box to type in value
      if (!isNaN(parseInt(shortcutKey))) {
        const cargoInputBox = document.getElementById(
          `cargo-input-${this.state.selectedCargo}`
        );
        if (cargoInputBox) {
          cargoInputBox.focus();
        }
      }

      // If ESC is pressed twice in a row, exit to home screen
      if (shortcutKey !== "Escape" && shortcutKey !== "Esc") {
        // Reset the exit flag if another key is pressed
        this.setState({ wantToExit: false });
      } else {
        if (this.state.wantToExit) {
          // Last keypress was also Esc, so go back to home screen
          this.setState({ goToNext: "/" });
        } else {
          // Don't exit yet, but do if next keypress is Esc
          this.setState({ wantToExit: true });
        }
      }

      switch (shortcutKey) {
        case keymaps.travelScreen:
          if (this.state.currentGame.turnsLeft > 1) {
            this.setState({ goToNext: "/play/travel" });
          } else {
            this.handleEndGame();
          }
          break;

        case keymaps.maxBuy:
        case keymaps.maxSell:
          const maxSide = shortcutKey === keymaps.maxBuy ? "buy" : "sell";
          if (this.state.selectedCargo) {
            this.handleMarketChange(this.state.selectedCargo, `${maxSide}_max`);
          }
          break;

        case keymaps.cargoPrev:
        case keymaps.cargoNext:
          if (this.state.selectedCargo) {
            const direction =
              shortcutKey === keymaps.cargoPrev ? "previous" : "next";
            let otherCargo = orderedCargos[this.state.selectedCargo][direction];
            if (this.state.currentMarket[otherCargo] == null) {
              // Banned cargo, so skip it
              otherCargo = orderedCargos[otherCargo][direction];
            }
            this.setState({ selectedCargo: otherCargo });
          }
          break;

        case keymaps.do_sell:
        case keymaps.do_buy:
          const side = shortcutKey === keymaps.do_buy ? "buy" : "sell";
          this.postTradeTxn(side);
          break;

        case keymaps.service:
          const currentService =
            Services.PlanetDirectory[this.state.currentGame.planet];
          if (currentService != null) {
            this.setState({ goToNext: `/play/${currentService}` });
          }
          break;

        case "Tab":
          event.preventDefault();
          break;
        case "Enter":
          if (
            this.state.nextTransactionType != null &&
            this.marketInput !== zeroMarketInputs
          ) {
            // If transaction type can be deduced, submit the transaction if
            // there is at least one non-zero requested cargo txn
            this.postTradeTxn(this.state.nextTransactionType);
          }
          break;
        default:
          break;
      }
      return;
    }

    // On the Travel console
    if (window.location.pathname === "/play/travel") {
      if (shortcutKey === "Esc" || shortcutKey === "Escape") {
        this.setState({ goToNext: "/play/trade" });
      } else {
        const newPlanet = keymaps.planets[shortcutKey] || null;

        if (newPlanet != null) {
          this.postTravel(newPlanet);
        }
      }
      return;
    }

    // must be on one of the services screens, which handle their own shortcuts
    switch (shortcutKey) {
      case "Esc":
      case "Escape":
        // Escape goes back to trade screen
        if (document.activeElement.nodeName !== "INPUT") {
          this.setState({ goToNext: "/play/trade" });
        }
        break;
      default:
        // Remainder of shortcuts are handled by LoanShark and Bank components
        break;
    }
    event.preventDefault();

    this.setState({ lastShortcutKey: shortcutKey });
  };

  render() {
    // Check if we should redirect somewhere else
    if (
      this.state.goToNext &&
      window.location.pathname !== this.state.goToNext &&
      this.state.currentPopup == null
    ) {
      return <Redirect to={{ pathname: this.state.goToNext }} />;
    }

    return (
      <div
        className="game-background"
        id="shortcutHandler"
        ref={this.focusHolder}
        tabIndex={0}
        onKeyDown={this.handleShortcuts}
        onFocus={() => this.setState({ wantToExit: false })}
      >
        {this.state.currentPopup}
        <Link to="/" className={styles.gameHeaderLinkFixed} tabIndex={-1} />
        <div className={styles.console}>
          <img src={console_background} className="console-background" alt="" />
          {/*Show upper status bar*/}
          <StatusBar currentGame={this.state.currentGame} />
          <div className={styles.panelHolder}>
            {/* Show appropriate console panel for buy/travel/bank/etc */}
            <Switch>
              <Route
                exact
                path="/play"
                render={() => <Redirect to={{ pathname: "/play/trade" }} />}
              />
              <Route
                exact
                path="/play/travel"
                render={() => (
                  <Travel
                    currentGame={this.state.currentGame}
                    doTravel={this.postTravel}
                    dropFocus={this.setFocusHolderFocus}
                  />
                )}
              />
              <Route
                exact
                path="/play/trade"
                render={() => (
                  <Trade
                    currentGame={this.state.currentGame}
                    handleEndGame={this.handleEndGame}
                    currentMarket={this.state.currentMarket}
                    handleChange={this.handleMarketChange}
                    handleSubmit={this.postTradeTxn}
                    marketInput={this.state.marketInput}
                    dropFocus={this.setFocusHolderFocus}
                    selectedCargo={this.state.selectedCargo}
                  />
                )}
              />
              <Route
                exact
                path="/play/fueldepot"
                render={() => (
                  <FuelDepot
                    planetName={this.state.currentGame.planet}
                    credits={this.state.currentGame.credits}
                    handleSubmit={this.postTxn}
                    fuelPurchases={this.state.currentGame.fuelPurchases}
                    shortcutKey={this.state.lastShortcutKey}
                  />
                )}
              />
              <Route
                exact
                path="/play/bank"
                render={() => (
                  <Bank
                    planetName={this.state.currentGame.planet}
                    bankBalance={this.state.currentGame.bankBalance}
                    credits={this.state.currentGame.credits}
                    handleSubmit={(side, qty) =>
                      this.postTxn("bank", side, qty)
                    }
                    shortcutKey={this.state.lastShortcutKey}
                  />
                )}
              />
              <Route
                exact
                path="/play/loanshark"
                render={() => (
                  <Loanshark
                    credits={this.state.currentGame.credits}
                    doTransaction={(side, qty) =>
                      this.postTxn("loanshark", side, qty)
                    }
                    dropFocus={this.setFocusHolderFocus}
                    loanBalance={this.state.currentGame.loanBalance}
                    planetName={this.state.currentGame.planet}
                    shortcutKey={this.state.lastShortcutKey}
                  />
                )}
              />
              <Route
                exact
                path="/play/shipyard"
                render={() => (
                  <Shipyard
                    credits={this.state.currentGame.credits}
                    currentBays={this.state.currentGame.totalBays}
                    planetName={this.state.currentGame.planet || ""}
                    handleSubmit={(side, qty) =>
                      this.postTxn("shipyard", side, qty)
                    }
                    shortcutKey={this.state.lastShortcutKey}
                  />
                )}
              />
            </Switch>
          </div>
        </div>
      </div>
    );
  }
}

export default Game;
