import React from "react";
import "../styles/Game.css";
import "../styles/StatusElement.css";

const StatusElement = props => {
  const containerClass = props.position + " status-element-box is-size-5";

  return (
    <div className={containerClass}>
      <div className="status-element" data-cy={props.testingName}>
        <span>{props.label}</span>
      </div>
      {/* If there is a value, display that too. */}
      {props.value && (
        <div className="status-element">
          <span>{props.value}</span>
        </div>
      )}
    </div>
  );
};

export default StatusElement;
