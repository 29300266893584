import React from "react";
import { withCommas } from "../utilities/utils";
import styles from "../styles/StatusBar.module.css";

import StatusElement from "./StatusElement";

const StatusBar = props => {
  return (
    <div className="bare-container">
      <StatusElement
        label={props.currentGame.planet}
        position={styles.positionPlanet}
        testingName="currentPlanet"
      />
      {/*Label and Value switched for credits intentionally */}
      <StatusElement
        label={withCommas(props.currentGame.credits)}
        value="Credits"
        position={styles.positionCredits}
        testingName="credits"
      />
      <StatusElement
        label="Cargo Bays"
        value={props.currentGame.usedBays + "/" + props.currentGame.totalBays}
        position={styles.positionCargoBays}
        testingName="cargoBaysString"
      />
      <StatusElement
        label="Turns"
        value={props.currentGame.turnsLeft}
        position={styles.positionTurns}
        testingName="turnsRemaining"
      />
    </div>
  );
};

export default StatusBar;
