import React, { memo } from "react";
import PropTypes from "prop-types";
import { flavorText, planetImages } from "../configuration/GameSettings";
import styles from "../styles/PlanetInfo.module.css";

const PlanetInfo = ({ planetName }) => {
  if (!planetName) {
    return null;
  }
  planetName = planetName.toLowerCase();

  const planetPic = planetImages[planetName];

  const planetInfo = flavorText[planetName];
  const infoText = (
    <span>
      {planetInfo.name}
      <br />
      <br />
      {planetInfo.stance}
      <br />
      population: {planetInfo.population}
      <br />
      planet age: {planetInfo.age}
      <br />
      species: {planetInfo.species}
    </span>
  );

  return (
    <div>
      <img className={styles.planetImage} src={planetPic} alt="" />
      <div className={styles.planetInfo}>{infoText}</div>
    </div>
  );
};

PlanetInfo.propTypes = {
  planetName: PropTypes.string.isRequired,
};

export default memo(PlanetInfo);
