import React, { Component } from "react";
import { Link, Redirect, Route } from "react-router-dom";
import PropTypes from "prop-types";

import axios from "axios";
import Qs from "qs";
import styles from "../styles/Welcome.module.scss";
import { withCommas } from "../utilities/utils";
import Instructions from "./Instructions";

const MAX_SCORES = 10;

class Welcome extends Component {
  state = {
    scores: {
      allTime: [],
      weekly: [],
    },
    startGame: false,
    goToNext: null,
    feedList: [],
  };

  shortcutController = React.createRef();

  componentDidMount() {
    if (process.env.NODE_ENV === "test") {
      return;
    }
    // Pull scores from the game database
    axios
      .get("/scores/list", {
        params: {
          length: 10,
          scoreType: ["allTime", "weekly"],
        },
        paramsSerializer: params => {
          return Qs.stringify(params, { arrayFormat: "repeat" });
        },
      })
      .then(res => {
        this.setState({ scores: res.data.scores });
      })
      .catch(err => {
        console.info("Error when trying to get scores", err);
      });

    // Pull news feed
    axios
      .get("/news_feed/get_all")
      .then(res => {
        this.setState({ feedList: res.data });
      })
      .catch(err => {
        console.info("Failed to get news feed: ", err);
      });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (this.shortcutController.current) {
      this.shortcutController.current.focus();
    }

    // Check if we were just redirected to this page (i.e., from Instructions)
    // and clear the navigation flag if so
    if (window.location.pathname === this.state.goToNext) {
      this.setState({ goToNext: null });
    }
  }

  handleShortcuts = evt => {
    if (evt.key === " ") {
      // Space-bar from main screen starts a game
      // Space-bar from instructions window goes back to main screen
      const whereToGoNext = window.location.pathname === "/" ? "/play" : "/";
      this.setState({ goToNext: whereToGoNext });
    }
  };

  render() {
    // Check if we should redirect somewhere else
    if (
      this.state.goToNext &&
      window.location.pathname !== this.state.goToNext
    ) {
      return <Redirect to={{ pathname: this.state.goToNext }} />;
    }
    return (
      <div
        className={styles.container}
        tabIndex={-1}
        onFocus={() => this.shortcutController.current.focus()}
      >
        <Route exact path="/instructions" component={Instructions} />
        <div
          id="shortcutController"
          tabIndex={0}
          ref={this.shortcutController}
          onKeyDown={this.handleShortcuts}
        />
        <div className={styles.logo} tabIndex={-1} />
        <div className={styles.console} tabIndex={-1}>
          {/* News Feed */}
          <Feed feedList={this.state.feedList} tabIndex={-1} />
          {/* Play button area*/}
          <div className={styles.playBtnBackground}>
            <Link
              to="/play"
              className={styles.playButton}
              data-cy="playButton"
            />
          </div>
          {/* High scores list */}
          <Scores scoreList={this.state.scores} tabIndex={-1} />
        </div>
      </div>
    );
  }
}

const Scores = props => {
  return (
    <div className={styles.scoreContainer}>
      <Link
        to="/instructions"
        className={styles.instructionButton}
        data-cy="instructionsButton"
      />
      <div className={styles.scoreBackground}>
        <ScoreList scores={props.scoreList.allTime} scoreType="allTime" />
        <ScoreList scores={props.scoreList.weekly} scoreType="weekly" />
      </div>
    </div>
  );
};

Scores.propTypes = {
  scoreList: PropTypes.object.isRequired,
};

const ScoreList = props => {
  // Limit to 10 scores so they all fit
  const scoreList = props.scores.slice(0, MAX_SCORES);

  return (
    <div className={`${styles.scoreList} ${styles[props.scoreType]}`}>
      {/* Map each score into a div each for name and score */}
      {scoreList.map((scoreItem, key) => {
        const { name, score } = scoreItem;
        return (
          <div className={styles.scoreItem} key={key}>
            <div className={styles.scoreName}>{name.slice(0, 10)}</div>
            <div className={styles.scoreScore}>{withCommas(score)}</div>
          </div>
        );
      })}
    </div>
  );
};

ScoreList.propTypes = {
  scores: PropTypes.array.isRequired,
};

const Feed = props => (
  <div className={styles.feed} data-cy="feed">
    <div className={styles.feedBackground}>
      <div className={styles.feedText} data-cy="feedText">
        {props.feedList.map((item, idx) => {
          const { itemDate, itemMessage } = item;
          return (
            <span key={idx} className={styles.feedItems}>
              {itemDate}:
              <br />
              {itemMessage}
              <br />
              <br />
            </span>
          );
        })}
      </div>
    </div>
    <div className={styles.feedBottomFlash}>
      {/*<button>Statistics</button>*/}
      {/*<button>Login</button>*/}
      {/*<button>Sign Up</button>*/}
    </div>
  </div>
);

Feed.propTypes = {
  feedList: PropTypes.array.isRequired,
};

export default Welcome;
