import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";

import styles from "../styles/Trade.module.css";
import btnStyles from "../styles/ButtonStyles.module.scss";

import iconMining from "../images/decoration/iconMining.png";
import iconMedical from "../images/decoration/iconMedical.png";
import iconNarcotics from "../images/decoration/iconNarcotics.png";
import iconWeapons from "../images/decoration/iconWeapons.png";
import iconWater from "../images/decoration/iconWater.png";
import iconMetal from "../images/decoration/iconMetal.png";

import { Services } from "../configuration/GameSettings";
import { withCommas } from "../utilities/utils";
import PlanetInfo from "./PlanetInfo";

const Trade = props => {
  // Avoid multiple re-renderings if game isn't gotten from server yet
  if (props.currentGame.credits == null) {
    return null;
  }

  // Determine if this planet offers a special service, and show a button for
  // it if it does.
  const serviceBtn = planet => {
    const serviceType = Services.PlanetDirectory[planet];
    if (serviceType) {
      return (
        <Link
          to={`/play/${serviceType}`}
          className={btnStyles[`${serviceType}Button`]}
          data-cy={"serviceButton"}
        />
      );
    }
    return null;
  };

  return (
    <div className={styles.panelTrade}>
      <PlanetInfo planetName={props.currentGame.planet} />

      {/* Show the market data for the current planet */}
      <Market
        currentMarket={props.currentMarket}
        handleChange={props.handleChange}
        marketInput={props.marketInput}
        handleSubmit={props.handleSubmit}
        cargoHold={props.currentGame.currentHold}
        dropFocus={props.dropFocus}
        selectedCargo={props.selectedCargo}
      />
      {/* Add a services button if there is a service on this planet*/}
      {serviceBtn(props.currentGame.planet)}

      {/* Figure out whether to make the 'travel' button show the travel screen
          or to end the game based on turns remaining */}
      {props.currentGame.turnsLeft > 1 ? (
        <Link
          to="/play/travel"
          className={btnStyles.travelButton}
          data-cy="travelButton"
        />
      ) : (
        <button
          className={btnStyles.travelButton}
          data-cy="endGameButton"
          onClick={props.handleEndGame}
        />
      )}
    </div>
  );
};

const Market = props => {
  const cargoDescriptor = [
    {
      icon: iconMining,
      label: "mining equip",
      type: "mining",
    },

    {
      icon: iconMedical,
      label: "med supplies",
      type: "medical",
    },

    {
      icon: iconNarcotics,
      label: "narcotics",
      type: "narcotics",
    },

    {
      icon: iconWeapons,
      label: "weapons",
      type: "weapons",
    },
    {
      icon: iconWater,
      label: "water",
      type: "water",
    },
    {
      icon: iconMetal,
      label: "metal",
      type: "metal",
    },
  ];

  const cargo = props.currentMarket;
  const hold = props.cargoHold;

  // Update CargoMap with values from API
  cargoDescriptor.forEach(currentMarket => {
    currentMarket.price = cargo[currentMarket.type] || "-------";
    currentMarket.onBoard = hold[currentMarket.type];
  });

  const MarketHeader = (
    <div className={styles.marketHeader}>
      <span className={styles.cargoIcon} />
      <span className={styles.cargoName}>Commodity</span>
      <span className={styles.cargoPrice}>Price</span>
      <span className={styles.cargoOnBoard}>onboard</span>
      <span className={styles.cargoQuantity} />
      <span className={styles.maxHeader}>max</span>
    </div>
  );

  const dropFocusMonitor = e => {
    // Either Tab or Esc can clear focus from input box
    if (e.key === "Tab" || e.key === "Escape" || e.key === "Enter") {
      e.preventDefault();
      props.dropFocus();
    }
  };

  return (
    <div>
      {MarketHeader}
      <div className={styles.market}>
        {cargoDescriptor.map(currentMarket => {
          const qtyToTrade = props.marketInput[currentMarket.type] || "";

          // Highlight the cargo row that is currently selected
          const selectedStyle =
            props.selectedCargo === currentMarket.type
              ? ` ${styles.selectedCargo}`
              : "";

          return (
            <div key={currentMarket.type} className={styles.cargoRow}>
              <img
                className={styles.cargoIcon}
                src={currentMarket.icon}
                alt=""
              />
              <div className={styles.cargoName + selectedStyle}>
                {currentMarket.label}
              </div>
              <div className={styles.cargoPrice + selectedStyle}>
                {withCommas(currentMarket.price)}
              </div>
              <div className={styles.cargoOnBoard + selectedStyle}>
                {withCommas(currentMarket.onBoard)}
              </div>
              <input
                type="number"
                className={styles.cargoQuantity + selectedStyle}
                onChange={e =>
                  props.handleChange(currentMarket.type, e.target.value)
                }
                onKeyDown={dropFocusMonitor}
                value={qtyToTrade}
                disabled={typeof currentMarket.price !== "number"}
                id={`cargo-input-${currentMarket.type}`}
              />
              <div
                className={styles.maxButton + " " + styles.maxBuy}
                onClick={() =>
                  props.handleChange(currentMarket.type, "buy_max")
                }
              />
              <div
                className={styles.maxButton + " " + styles.maxSell}
                onClick={() =>
                  props.handleChange(currentMarket.type, "sell_max")
                }
              />
            </div>
          );
        })}
      </div>
      <div className={styles.transactionButtons}>
        <div
          className={styles.buyButton}
          onClick={() => props.handleSubmit("buy")}
        />
        <div
          className={styles.sellButton}
          onClick={() => props.handleSubmit("sell")}
        />
      </div>
    </div>
  );
};

Market.propTypes = {
  cargoHold: PropTypes.object.isRequired,
  currentMarket: PropTypes.object.isRequired,
  dropFocus: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  marketInput: PropTypes.object.isRequired,
  selectedCargo: PropTypes.string,
};

Trade.propTypes = {
  currentGame: PropTypes.object.isRequired,
  currentMarket: PropTypes.object.isRequired,
  dropFocus: PropTypes.func.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
  marketInput: PropTypes.object.isRequired,
  selectedCargo: PropTypes.string,
};

export default Trade;
