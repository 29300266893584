import React from "react";

function withCommas(x) {
  if (typeof x !== "number") {
    return x;
  }
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export { withCommas };

export const orderedCargos = {
  medical: {
    next: "narcotics",
    previous: "mining",
  },
  metal: {
    next: "mining",
    previous: "water",
  },
  mining: {
    next: "medical",
    previous: "metal",
  },
  narcotics: {
    next: "weapons",
    previous: "medical",
  },
  water: {
    next: "metal",
    previous: "weapons",
  },
  weapons: {
    next: "water",
    previous: "narcotics",
  },
};
export const breakOnNewlines = (text, style = "") => {
  // Standardize to a single type of newline
  text = text.replace(/(\r\n|\n|\r)/, "\n");
  return text.split("\n").map((item, key) => {
    return (
      <span className={style} key={key}>
        {item}
        <br />
      </span>
    );
  });
};
