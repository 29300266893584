import React, { Component } from "react";
import { Route, Switch } from "react-router-dom";

import Welcome from "./components/Welcome";
import Game from "./components/Game";

class App extends Component {
  render() {
    return (
      <div>
        <Switch>
          <Route exact path="/" component={Welcome} />
          <Route exact path="/instructions" component={Welcome} />
          <Route path="/play" component={Game} />
        </Switch>
      </div>
    );
  }
}

export default App;
